<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Room Add-ons Lists') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <Filter
              @filter="fetchData"
            />
          </div>
          <div
            class="column is-narrow"
          >
            <Modal ref="modalRoomAddon">
              <template #default="{hide}">
                <Form
                  :value="selected"
                  @close="() => {
                    hide();
                    fetchData();
                  }"
                  @cancel="() => {
                    hide();
                  }"
                />
              </template>
              <template #handler="{show}">
                <a
                  class="ant-btn ant-btn-lg ant-btn-primary"
                  @click.prevent="() => {
                    show();
                    selected = undefined;
                  }"
                >
                  {{ $t('Add Room Add-ons') }}
                </a>
              </template>
            </Modal>
          </div>
        </div>
        <a-table
          row-key="id"
          row-class-name="pointer"
          :custom-row="selectRow"
          :columns="columns"
          :data-source="lists"
          :loading="isFetching"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #adrLift="{ text }">
            {{ $filters.number(text) }} JPY
          </template>
          <template #occ="{ text }">
            {{ $filters.number(text) }}%
          </template>
          <template #createdAt="{ text }">
            {{ $filters.date(text, { format: $variables.genericDateTimeFormat }) }}
          </template>
          <template #updatedAt="{ text }">
            {{ $filters.date(text, { format: $variables.genericDateTimeFormat }) }}
          </template>
          <template #action="{ record }">
            <Modal>
              <template #default="{hide}">
                <Delete
                  :value="record"
                  @close="()=>{hide();fetchData();}"
                  @cancel="hide"
                />
              </template>
              <template #handler="{show}">
                <DeleteFilled
                  class="has-text-danger"
                  @click.stop.prevent="show"
                />
              </template>
            </Modal>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { DeleteFilled } from '@ant-design/icons-vue';
import Modal from '@/components/Modal';
import pagination from '@/components/mixins/pagination';
import Delete from '@/views/simulations/components/DelRoomAddon';
import Form from '@/views/simulations/components/RoomAddonForm';
import Filter from '@/views/simulations/components/FilterAddon';

export default {
  name: 'RoomAddons',
  components: {
    Modal,
    Delete,
    DeleteFilled,
    Form,
    Filter,
  },
  mixins: [pagination],
  data() {
    return {
      lists: undefined,
      selected: undefined,
      isFetching: false,
      columns: [
        {
          title: 'Features',
          dataIndex: 'feature',
          key: 'feature',
          width: '200px',
        },
        {
          title: 'ADR Lift',
          dataIndex: 'adrLift',
          key: 'adrLift',
          slots: { customRender: 'adrLift' },
        },
        {
          title: 'DOR',
          dataIndex: 'dor',
          key: 'dor',
          slots: { customRender: 'dor' },
        },
        {
          title: 'LOS',
          dataIndex: 'los',
          key: 'los',
          slots: { customRender: 'los' },
        },
        {
          title: 'OCC',
          dataIndex: 'occ',
          key: 'occ',
          slots: { customRender: 'occ' },
        },
        {
          title: 'Generated at',
          dataIndex: 'createdAt',
          key: 'createdAt',
          slots: { customRender: 'createdAt' },
        },
        {
          title: 'Updated at',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          slots: { customRender: 'updatedAt' },
        },
        {
          title: ' ',
          key: 'action',
          align: 'right',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.setWidth();
    window.addEventListener('resize', () => {
      this.setWidth();
    });
  },
  methods: {
    setWidth() {
      if (window.innerWidth < 550) {
        this.columns.forEach((col, idx) => {
          if (col.key !== 'spacing' && col.key !== 'action') {
            this.columns[idx].width = `${130}px`;
          }
        });
      } else {
        this.columns.forEach((col, idx) => {
          if (col.key !== 'spacing' && col.key !== 'action') {
            delete this.columns[idx].width;
          }
        });
      }
    },
    selectRow(record) {
      return {
        onClick: () => {
          this.selected = record;
          this.$refs.modalRoomAddon.show();
        },
      };
    },
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      return this.$store
        .dispatch('simulations/listRoomAddons', this.$route.query)
        .then((resp) => {
          const { data, pagination } = resp;

          this.lists = data;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
